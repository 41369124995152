API.Shoutbox = {};

API.Shoutbox.ReloadMessages = function() {
  $.ajax({
    type: "POST",
    url: "/api/getMessages.php",
    success: function(data) {
      if (API.Shoutbox.HandleResponse(data, false)) {
        $("#modal-shoutbox-messages").html("");
        $("#modal-shoutbox form").trigger('reset');
        $('input[data-length], textarea[data-length]').characterCounter("updateCounter");
        for (var index in data.data) {
          var message = data.data[index];
          if (data.data.hasOwnProperty(index)) {
            $("#modal-shoutbox-messages").append(`
            <div class="col s12">
                <div class="card burt4 ${message.admin == 1 ? "primary white-text" : ""}">
                    <div class="card-content">
                      <div class="row">
                        <div class="col s6 mw-50">
                          <p>
                            <b>
                            ${message.username}
                            </b>
                          </p>
                        </div>
                        <div class="col s6 mw-50">
                          <p class="right">${message.date}</p>
                        </div>
                      </div>
                      <p class="light">${message.content}</p>
                    </div>
                </div>
              </div>
            `);
          }
        }
      }
    }
  });
};

API.Shoutbox.HandleResponse = function(data, displaySuccess = true) {
  if (data.error || displaySuccess) {
    var messageTemplate = `
    <div class="row" style="margin-bottom: 0px; margin-top: 10px;">
      <div class="col s12">
        <div class="card-panel ${data.error ? "error" : "success"}">
          <span class="white-text">${data.message}</span>
        </div>
      </div>
    </div>
    `;
    var item = $(messageTemplate);
    $("#modal-shoutbox-status").append(item);
    setTimeout(function () {
      item.remove();
    }, 2000);
  }

  return !data.error; //Valid
}
API.Shoutbox.SetupAjax = function() {

  // capture submit
  $('#modal-shoutbox form').submit(function() {
    $theForm = $(this);

    // send xhr request
    $.ajax({
      type: $theForm.attr('method'),
      url: $theForm.attr('action'),
      data: $theForm.serialize(),
      success: function(data) {
        API.Shoutbox.HandleResponse(data);
        API.Shoutbox.ReloadMessages();
      }
    });

    // prevent submitting again
    return false;
  });
}

$(function() {
  API.Shoutbox.SetupAjax();
  API.Shoutbox.ReloadMessages();
})
